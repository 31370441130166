<template>
  <div class="game-container">
    <RecognitionPinyinGame :optionList="optionList" :imgList="imgList" :isHaveAudio="true"  :limitNumberOfVisibleOptions="3" :isFullScreen="true"/>
  </div>
</template>

<script>
import RecognitionPinyinGame from "@/components/Course/GamePage/RecognitionPinyinGame";
export default {
  data() {
    return {
      lessonId: 8,
      optionList: [
        {
          id: 1,
          image: require("@/assets/img/16-GAMES/G51-frogs/chapter-06/background-ai.svg"),
        },
        {
          id: 2,
          image: require("@/assets/img/16-GAMES/G51-frogs/chapter-06/background-ao.svg"),
        },
        {
          id: 3,
          image: require("@/assets/img/16-GAMES/G51-frogs/chapter-06/background-ei.svg"),
        },
         {
          id: 4,
          image: require("@/assets/img/16-GAMES/G51-frogs/chapter-06/background-er.svg"),
        },
         {
          id: 5,
          image: require("@/assets/img/16-GAMES/G51-frogs/chapter-06/background-ie.svg"),
        },
         {
          id: 6,
          image: require("@/assets/img/16-GAMES/G51-frogs/chapter-06/background-iu.svg"),
        },
         {
          id: 7,
          image: require("@/assets/img/16-GAMES/G51-frogs/chapter-06/background-ou.svg"),
        },
         {
          id: 8,
          image: require("@/assets/img/16-GAMES/G51-frogs/chapter-06/background-ue.svg"),
        },
         {
          id: 9,
          image: require("@/assets/img/16-GAMES/G51-frogs/chapter-06/background-ui.svg"),
        },
      ],

      imgList: [
        {
          id: 1,
          bottomImg: require("@/assets/img/16-GAMES/G51-frogs/chapter-06/frog-ai.svg"),
          nobgcolor: true,
          audioSrc: require("@/assets/audio/L1/2-Pinyin/ai.mp3"),

        },
        {
          id: 2,
          bottomImg: require("@/assets/img/16-GAMES/G51-frogs/chapter-06/frog-ao.svg"),
          nobgcolor: true,
          audioSrc: require("@/assets/audio/L1/2-Pinyin/ao.mp3"),

        },
        {
          id: 3,
          bottomImg: require("@/assets/img/16-GAMES/G51-frogs/chapter-06/frog-ei.svg"),
          nobgcolor: true,
          audioSrc: require("@/assets/audio/L1/2-Pinyin/ei.mp3"),

        },
        {
          id: 4,
          bottomImg: require("@/assets/img/16-GAMES/G51-frogs/chapter-06/frog-er.svg"),
          nobgcolor: true,
          audioSrc: require("@/assets/audio/L1/2-Pinyin/er.mp3"),
        },
        {
          id: 5,
          bottomImg: require("@/assets/img/16-GAMES/G51-frogs/chapter-06/frog-ie.svg"),
          nobgcolor: true,
          audioSrc: require("@/assets/audio/L1/2-Pinyin/ie.mp3"),
        },
        {
          id: 6,
          bottomImg: require("@/assets/img/16-GAMES/G51-frogs/chapter-06/frog-iu.svg"),
          nobgcolor: true,
          audioSrc: require("@/assets/audio/L1/2-Pinyin/iu.mp3"),
        },
        {
          id: 7,
          bottomImg: require("@/assets/img/16-GAMES/G51-frogs/chapter-06/frog-ou.svg"),
          nobgcolor: true,
          audioSrc: require("@/assets/audio/L1/2-Pinyin/ou.mp3"),
        },
        {
          id: 8,
          bottomImg: require("@/assets/img/16-GAMES/G51-frogs/chapter-06/frog-ve.svg"),
          nobgcolor: true,
          audioSrc: require("@/assets/audio/L1/2-Pinyin/ve.mp3"),
        },
        {
          id: 9,
          bottomImg: require("@/assets/img/16-GAMES/G51-frogs/chapter-06/frog-ui.svg"),
          nobgcolor: true,
          audioSrc: require("@/assets/audio/L1/2-Pinyin/ui.mp3"),
        },
       
      ],
    };
  },
  components: {
    RecognitionPinyinGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











