<template>
  <div class="game-container">
    <DragToCakeGame
      :bgImgList="bgImgList"
      :bgImg="bgImg"
      :optionImgList="optionImgList"
      :lessonNO="48"
      :titleInfo="titleInfo"
      :hasSentence="true"
    />
  </div>
</template>

<script>
import DragToCakeGame from "@/components/Course/GamePage/DragToCakeGame";
export default {
  data() {
    return {
      titleInfo: {
        hanzi: "真……",
        pinyin: "zhēn",
      },
      bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-48/background-1.svg"),
      bgImgList: [
        {
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-48/ears-da-done.svg"),
          id: 1,
          index: 1,
          group: 1,
          pinyin: "Tā de ěrduo zhēn dà.",
          hanzi: "她的耳朵真大。",
        },
        {
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-48/ears-xiao-done.svg"),
          id: 2,
          index: 2,
          group: 1,
          pinyin: "Tā de ěrduo zhēn xiǎo.",
          hanzi: "她的耳朵真小。",
        },
        {
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-48/eyes-da-done.svg"),
          id: 3,
          index: 3,
          group: 2,
          pinyin: "Tā de yǎnjing zhēn dà.",
          hanzi: "她的眼睛真大。",
        },
        {
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-48/eyes-xiao-done.svg"),
          id: 4,
          index: 4,
          group: 2,
          pinyin: "Tā de yǎnjing zhēn xiǎo.",
          hanzi: "她的眼睛真小。",
        },
        {
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-48/hair-done.svg"),
          id: 5,
          index: 5,
          group: 3,
          pinyin: "Tā de tóufa zhēn cháng!",
          hanzi: "她的头发真长！",
        },
        {
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-48/nose-da-done.svg"),
          id: 6,
          index: 6,
          group: 4,
          pinyin: "Tā de bízi zhēn dà.",
          hanzi: "她的鼻子真大。",
        },
        {
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-48/nose-xiao-done.svg"),
          id: 7,
          index: 7,
          group: 4,
          pinyin: "Tā de bízi zhēn xiǎo.",
          hanzi: "她的鼻子真小。",
        },
       
      ],
      optionImgList: [
        {
          id: 1,
          index: 1,
          group: 1,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-48/ears-da.svg"),
        },
        {
          id: 2,
          index: 2,
          group: 1,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-48/ears-xiao.svg"),
        },
        {
          id: 3,
          index: 3,
          group: 2,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-48/eyes-2.svg"),
        },
        {
          id: 4,
          index: 4,
          group: 2,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-48/eyes-1.svg"),
        },
         {
          id: 5,
          index: 5,
          group: 3,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-48/hair.svg"),
        },
        {
          id: 6,
          index: 6,
          group: 4,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-48/nose-da.svg"),
        },
        {
          id: 7,
          index: 7,
          group: 4,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-48/nose-xiao.svg"),
        },
       
      ],
     
    };
  },
  components: {
    DragToCakeGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











