<template>
  <div class="game-container">
    <ReviewTrain
      :step="3"
      :vocabularyReviewList="vocabularyReviewList"
      :knowledgeReviewList="knowledgeReviewList"
      :pinyinWordReviewList="pinyinWordReviewList"
      :strokesReviewList="strokesReviewList"
    />
  </div>
</template>

<script>
import ReviewTrain from "@/components/Course/CoursePage/ReviewTrain";
export default {
  data() {
    return {
     
     vocabularyReviewList: [
        [
          {
            pinyin: "xǐhuan",
            hanzi: "喜欢",
          },
          {
            pinyin: "chī",
            hanzi: "吃",
          },
          {
            pinyin: "ma",
            hanzi: "吗",
          },
          {
            pinyin: "píngguǒ",
            hanzi: "苹果",
          },
          {
            pinyin: "xièxie",
            hanzi: "谢谢",
          },
          {
            pinyin: "hē",
            hanzi: "喝",
          },
        ],
        [
          {
            pinyin: "niúnǎi",
            hanzi: "牛奶",
          },
          {
            pinyin: "shéi",
            hanzi: "谁",
          },
          {
            pinyin: "mǐfàn",
            hanzi: "米饭",
          },
          {
            pinyin: "miàntiáor",
            hanzi: "面条儿",
          },
          {
            pinyin: "dàngāo",
            hanzi: "蛋糕",
          },
          {
            pinyin: "zhēn",
            hanzi: "真",
          },
        ],
        
      ],
      knowledgeReviewList: [
        [
          {
            pinyin: "...ma?",
            hanzi: "……吗？",
          },
          {
            pinyin: "Xièxie...",
            hanzi: "谢谢……",
          },
          {
            pinyin: "Zhēn...",
            hanzi: "真……",
          },
        ],
      
      ],
      pinyinWordReviewList: [
        [
          {
            vocabulary: "ai",
          },
          {
            vocabulary: "ei",
          },
          {
            vocabulary: "ui",
          },
          {
            vocabulary: "ao",
          },
          
        ],
        [
          {
            vocabulary: "ou",
          },
          {
            vocabulary: "iu",
          },
          {
            vocabulary: "ie",
          },
           {
            vocabulary: "üe",
          },
          {
            vocabulary: "er",
          },
          
        ],
      ],
      strokesReviewList: [
        [
          {
            strokeImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-42/42-henggou-white.svg"),
          },
          {
            strokeImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-44/44-shugou-white.svg"),
          },
        ],
        [
          {
            strokeImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-46/46-wangou-white.svg"),
          },
         
        ],
      ],
    };
  },
  components: {
    ReviewTrain,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>