<template>
  <div class="game-container">
    <WriteHanziReviewPage
      :LeftImgList="LeftImgList"
      :hanziInfoList="hanziInfoList"
      :gifList="gifList"
    />
  </div>
</template>

<script>
import WriteHanziReviewPage from "@/components/Course/CoursePage/WriteHanziReviewPage";
export default {
  data() {
    return {
      hanziInfoList: [
        {
          pinyin: "shuǐ",
          tipsList: ["我", "喜", "欢", "喝", "水", "。"],
          bgImg: require("@/assets/img/16-GAMES/G20-writing-hanzi/lesson-44/hanzi-shui-grey-border.svg"),
          stepNumber: 4,
          tipsIndex: 4,
        },
        {
          pinyin: "mǐ",
          tipsList: ["我", "不", "吃", "米", "饭", "。"],
          bgImg: require("@/assets/img/16-GAMES/G20-writing-hanzi/lesson-46/hanzi-mi-grey-border.svg"),
          stepNumber: 6,
          tipsIndex: 3,
        },
        {
          pinyin: "mā",
          tipsList: ["这", "儿", "有", "牛", "奶", "吗", "？"],
          bgImg: require("@/assets/img/16-GAMES/G20-writing-hanzi/lesson-42/hanzi-ma-grey-border.svg"),
          stepNumber: 6,
          tipsIndex: 5,
        },
      ],
    };
  },
  computed: {
    LeftImgList() {
      const imgArr = [];
      for (let i = 1; i <= 3; i++) {
        imgArr.push(
          require(`@/assets/img/16-GAMES/G20-writing-hanzi/lesson-48/image-${i}.svg`)
        );
      }
      return imgArr;
    },
    gifList() {
      let imgArrMa = [];
      for (let i = 1; i <= 6; i++) {
        imgArrMa.push(
          require(`@/assets/img/16-GAMES/G20-writing-hanzi/lesson-42/lesson-42-ma-${i}.gif`)
        );
      }
      let imgArrYou = [];
      for (let i = 1; i <= 6; i++) {
        imgArrYou.push(
          require(`@/assets/img/16-GAMES/G20-writing-hanzi/lesson-46/lesson-46-mi-${i}.gif`)
        );
      }
      let imgArrRen = [];
      for (let i = 1; i <= 4; i++) {
        imgArrRen.push(
          require(`@/assets/img/16-GAMES/G20-writing-hanzi/lesson-44/lesson-44-shui-${i}.gif`)
        );
      }
      return [imgArrRen, imgArrYou, imgArrMa];
    },
  },
  components: {
    WriteHanziReviewPage,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
