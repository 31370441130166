<template>
  <div class="game-container">
    <DragSameCorrectPicGame
      :answerList="answerList"
      :optionsList="optionsList"
      @changeAnswerListStatus="changeAnswerListStatus"
      @changeOptionsListStatus="changeOptionsListStatus"
      :starNum="7"
      :imgNo="3"
    />
  </div>
</template>

<script>
import DragSameCorrectPicGame from "@/components/Course/GamePage/DragSameCorrectPicGame";
export default {
  data() {
    return {
      curIndex: null,
      originList: [],
      bgImage: require("@/assets/img//03-Backgrounds/level-1-chapter-6-background-blue.svg"),
      answerList: [
        {
          id: 3,
          index: 1,
          image: require("@/assets/img/16-GAMES/G49-10-numbers/lesson-48/1-nihao.svg"),
        },
        {
          index: 2,
          id: 1,
          image: require("@/assets/img/16-GAMES/G49-10-numbers/lesson-48/2-xiexie.svg"),
        },
        {
          id: 2,
          index: 3,
          image: require("@/assets/img/16-GAMES/G49-10-numbers/lesson-48/3-zaijian.svg"),
        },
        {
          id: 3,
          index: 4,
          image: require("@/assets/img/16-GAMES/G49-10-numbers/lesson-48/4-nihao.svg"),
        },
        {
          id: 1,
          index: 5,
          image: require("@/assets/img/16-GAMES/G49-10-numbers/lesson-48/5-xiexie.svg"),
        },
        {
          id: 2,
          index: 6,
          image: require("@/assets/img/16-GAMES/G49-10-numbers/lesson-48/6-zaijian.svg"),
        },
        {
          id: 1,
          index: 7,
          image: require("@/assets/img/16-GAMES/G49-10-numbers/lesson-48/7-xiexie.svg"),
        },
      ],

      optionsList: [
        {
          id: 1,
          index: 1,
          done: false,
          bgImg: require("@/assets/img/16-GAMES/G49-10-numbers/lesson-48/button-xiexie.svg"),
        },
        {
          id: 2,
          index: 2,
          done: false,
          bgImg: require("@/assets/img/16-GAMES/G49-10-numbers/lesson-48/button-zaijian.svg"),
        },
        {
          id: 3,
          index: 3,
          done: false,
          bgImg: require("@/assets/img/16-GAMES/G49-10-numbers/lesson-48/button-nihao.svg"),
        },
        {
          id: 1,
          index: 4,
          done: false,
          bgImg: require("@/assets/img/16-GAMES/G49-10-numbers/lesson-48/button-xiexie.svg"),
        },
        {
          id: 3,
          index: 5,
          done: false,
          bgImg: require("@/assets/img/16-GAMES/G49-10-numbers/lesson-48/button-nihao.svg"),
        },
        {
          id: 2,
          index: 6,
          done: false,
          bgImg: require("@/assets/img/16-GAMES/G49-10-numbers/lesson-48/button-zaijian.svg"),
        },
        {
          id: 1,
          index: 7,
          done: false,
          bgImg: require("@/assets/img/16-GAMES/G49-10-numbers/lesson-48/button-xiexie.svg"),
        },
      ],
    };
  },
  components: {
    DragSameCorrectPicGame,
  },
  created() {
    this.originList = JSON.parse(JSON.stringify(this.answerList));
  },
  methods: {
    changeAnswerListStatus(index) {
      this.answerList.forEach((item, curIndex) => {
        if (item.index === index) {
          this.curIndex = curIndex;
        }
      });
      console.log(index, this.curIndex);
      this.answerList.splice(this.curIndex, 1);
    },
    changeOptionsListStatus(id, index) {
      console.log(id);
      this.optionsList.forEach((ele) => {
        if (ele.index === id) {
          ele.done = true;
          ele.bgImg = this.originList[index - 1].image;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











