<template>
  <div class="game-container">
    <FindElementByPicReviewGame
      :asideElementInfo="asideElementInfo"
      :questionInfoList="questionInfoList"
      :totalStars="totalStars"
      :isNumberGame="false"
      :background="`blue`"
    />
  </div>
</template>

<script>
import FindElementByPicReviewGame from "@/components/Course/GamePage/FindElementByPicReviewGame";
export default {
  data() {
    return {
      totalStars: 6,
      questionInfoList: [
        {
          id: 2,
          bgImg: require("@/assets/img/12-Vocabulary/lesson-43-shui.svg"),
          sound: require("@/assets/audio/L1/5-Words/shui3.mp3")
        },
        {
          id: 4,
          bgImg: require("@/assets/img/12-Vocabulary/lesson-45-dangao.svg"),
          sound: require("@/assets/audio/L1/5-Words/dangao.mp3")
        },
        {
          id: 1,
          bgImg: require("@/assets/img/12-Vocabulary/lesson-45-mifan.svg"),
          sound: require("@/assets/audio/L1/5-Words/mifan.mp3")
        },
        {
          id: 6,
          bgImg: require("@/assets/img/12-Vocabulary/lesson-45-miantiao.svg"),
          sound: require("@/assets/audio/L1/5-Words/miantiaor.mp3")
        },
        {
          id: 5,
          bgImg: require("@/assets/img/12-Vocabulary/lesson-43-niunai.svg"),
          sound: require("@/assets/audio/L1/5-Words/niunai.mp3")
        },
        {
          id: 3,
          bgImg: require("@/assets/img/12-Vocabulary/lesson-41-pingguo.svg"),
          sound: require("@/assets/audio/L1/5-Words/pingguo.mp3")
        },
      ],
      asideElementInfo: {
        leftList: [
          {
            id: 1,
            element: "米饭",
            pinyin: "mǐfàn",
            sound: require("@/assets/audio/L1/5-Words/mifan.mp3")
          },
          {
            id: 2,
            element: "水",
            pinyin: "shuǐ",
            sound: require("@/assets/audio/L1/5-Words/shui3.mp3")
          },
          {
            id: 3,
            element: "苹果",
            pinyin: "píngguǒ",
            sound: require("@/assets/audio/L1/5-Words/pingguo.mp3")
          }
        ],
        rightList: [
          {
            id: 4,
            element: "蛋糕",
            pinyin: "dàngāo",
            sound: require("@/assets/audio/L1/5-Words/dangao.mp3")
          },
          {
            id: 5,
            element: "牛奶",
            pinyin: "niúnǎi",
            sound: require("@/assets/audio/L1/5-Words/niunai.mp3")
          },
          {
            id: 6,
            element: "面条儿",
            pinyin: "miàntiáor",
            sound: require("@/assets/audio/L1/5-Words/miantiaor.mp3")
          }
        ]
      }
    };
  },
  components: {
    FindElementByPicReviewGame
  }
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
